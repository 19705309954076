import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fdce570"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "myAccountContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailPassword = _resolveComponent("EmailPassword")!
  const _component_PhoneNumber = _resolveComponent("PhoneNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_EmailPassword, { id: "emailpasswordContainer" }),
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('EDIT_PHONE')), 1),
    _createVNode(_component_PhoneNumber, { id: "phoneNumberContainer" })
  ]))
}