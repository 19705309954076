
import {
    computed,
    defineComponent,
    inject,
    onBeforeMount,
    onMounted,
    ref,
} from 'vue'
import PersonalData from '@/components/PersonalData.vue'
import CancelAccount from '@/components/CancelAccount.vue'
import MyAccount from '@/components/MyAccount.vue'
import Ranking from '@/components/Ranking.vue'
import { IonSlides, IonSlide } from '@ionic/vue'

export default defineComponent({
    components: { PersonalData, CancelAccount, MyAccount, Ranking, IonSlides, IonSlide },
    setup() {
        const slideOpts = ref({
            initialSlide: 0,
            speed: 400,
            autoHeight: true,
        })

        const updateActualOptionIndex = async () => {
            actualOptionIndex.value =
                await ionSlides?.value?.$el?.getActiveIndex()
        }

        const ionSlides: any = ref(null)
        const actualOptionIndex = ref(0)
        const slideTo = (index: number) => {
            ionSlides?.value?.$el?.slideTo(index)
            updateActualOptionIndex()
        }

        return {
            slideOpts,
            slideTo,
            ionSlides,
            actualOptionIndex,
            updateActualOptionIndex,
        }
    },
})
