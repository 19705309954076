import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46a3bcb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "profileContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PersonalData = _resolveComponent("PersonalData")!
  const _component_CancelAccount = _resolveComponent("CancelAccount")!
  const _component_ion_slide = _resolveComponent("ion-slide")!
  const _component_MyAccount = _resolveComponent("MyAccount")!
  const _component_Ranking = _resolveComponent("Ranking")!
  const _component_ion_slides = _resolveComponent("ion-slides")!
  const _component_master_layout = _resolveComponent("master-layout")!

  return (_openBlock(), _createBlock(_component_master_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('Testers_profile_edit_profile_modal_profile_info')), 1),
        _createElementVNode("ul", null, [
          _createElementVNode("li", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.slideTo(0))),
            class: _normalizeClass({ selected: _ctx.actualOptionIndex == 0 })
          }, _toDisplayString(_ctx.$t('PERSONAL_DATA')), 3),
          _createElementVNode("li", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.slideTo(1))),
            class: _normalizeClass({ selected: _ctx.actualOptionIndex == 1 })
          }, _toDisplayString(_ctx.$t('MY_ACCOUNT')), 3),
          _createElementVNode("li", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.slideTo(2))),
            class: _normalizeClass({ selected: _ctx.actualOptionIndex == 2 })
          }, _toDisplayString(_ctx.$t('RANKING')), 3)
        ]),
        _createVNode(_component_ion_slides, {
          ref: "ionSlides",
          pager: "false",
          options: _ctx.slideOpts,
          onIonSlideWillChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateActualOptionIndex()))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_slide, { style: {"flex-direction":"column"} }, {
              default: _withCtx(() => [
                _createVNode(_component_PersonalData, {
                  id: "personalDataContainer",
                  class: "profileContentItem"
                }),
                _createVNode(_component_CancelAccount, { id: "cancelAccountContainer" })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_slide, null, {
              default: _withCtx(() => [
                _createVNode(_component_MyAccount, {
                  id: "myAccountContainer",
                  class: "profileContentItem"
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_slide, null, {
              default: _withCtx(() => [
                _createVNode(_component_Ranking, {
                  id: "rankingContainer",
                  class: "profileContentItem"
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["options"])
      ])
    ]),
    _: 1
  }))
}