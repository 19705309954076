
import { defineComponent} from 'vue'

export default defineComponent({
    props: {
        value: {}
    },
    setup(){
        const getLevelStatus =(type: string)=>{
            switch (type) {
                case 'REGULAR':
                    return 'PádaWAT'
                case 'PRO':
                    return 'WATpro'
                case 'GURU':
                    return 'WATguru'
            
                default:
                    break;
            }
        }
        return{
            getLevelStatus
        }
    }
})
